export default {
    namespaced: true,
    state: () => ({
        isPhaseLoading: false,
        phases: [],
        phaseAPI: ""
    }),
    getters: {
        getPhases(state) {
            return state.phases;
        },
        getPhaseIsLoading(state) {
            return state.isPhaseLoading;
        }
    },
    mutations: {
        setPhases(state, newPhases) {
            state.phases = newPhases;
        },
        setPhaseIsLoading(state, isLoading) {
            state.isPhaseLoading = isLoading;
        },
        setPhaseAPI(state, API) {
            state.phaseAPI = API;
        }
    },
    actions: {
        async refreshPhases({ commit, state }, projectId) {
            try {
                commit("setPhaseIsLoading", true);
                let data = await state.phaseAPI.getProjectPhases({
                    queries: {
                        "project:id": projectId
                    },
                    perPage: 20
                });
                commit("setPhases", data.data);
                commit("setPhaseIsLoading", false);
            } catch (error) {
                commit("setPhaseIsLoading", false);
                this._vm.$notify({
                    group: "alert",
                    type: "error",
                    title: "Error",
                    text: "Failed to get phases. Please try again later."
                });
            }
        }
    }
};
